import { template as template_21b1d6de74574180bbf329497ea4aeaa } from "@ember/template-compiler";
const SidebarSectionMessage = template_21b1d6de74574180bbf329497ea4aeaa(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
