import { template as template_bdc025d9423142fc869e0f591264e81b } from "@ember/template-compiler";
const FKText = template_bdc025d9423142fc869e0f591264e81b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
